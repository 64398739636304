import { ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const GenerateBusinessIdeasButton = () => {
  const navigate = useNavigate();
  
  return (
    <>
      <Button
        size="large"
        block
        className="pinkOrangeGradientButton adjust_button_hover_drawer"
        onClick={() => navigate("/new")}
        icon={<ArrowRightOutlined />}
      >
        Generate Business Ideas
      </Button>
    </>
  );
};

export default GenerateBusinessIdeasButton;
