import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { convertTime } from "../../../util";

const Archives = () => {
  const archives = useSelector((state) => state.blog.archives);
  const dates = archives.map((date) => convertTime(date));

  return (
    <>
      <div className="blogPostBody">
        <h1>Archives</h1>
        <ul>
          {dates.map((date) => (
            <li key={`${date.year}/${date.month}`}>
              <Link to={`/blog/archive/${date.year}/${date.month}`}>
                {date.year}/{date.month}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Archives;
