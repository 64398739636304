import axios from "axios";
import routes from "../routes";

const getBlogCategories = async () => {
  const { data } = await axios.get(routes.getBlogCategories);
  return data;
};

const getBlogArchives = async () => {
  const { data } = await axios.get(routes.getBlogArchives);
  return data;
};

const getRecentBlogs = async () => {
  const { data } = await axios.get(routes.getRecentBlogs);
  return data;
};

const getBlogBySlug = async (slug) => {
  const { data } = await axios.get(`${routes.getBlogBySlug}/${slug}`);
  return data;
};

const getBlogByCategory = async (category) => {
  const { data } = await axios.get(`${routes.getBlogsByCategory}/${category}`);
  return data;
};

const getBlogByArchive = async (date) => {
  const { data } = await axios.get(`${routes.getBlogsByArchive}/${date}`);
  return data;
};

export default {
  getBlogCategories,
  getBlogArchives,
  getRecentBlogs,
  getBlogBySlug,
  getBlogByCategory,
  getBlogByArchive,
};
