import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  archives: [],
  blogs: [],
};

const blogSlice = createSlice({
  name: "blog",
  initialState: initialState,
  reducers: {
    setBlogs(state, action) {
      return { ...state, blogs: action.payload };
    },
    setCategories(state, action) {
      return { ...state, categories: action.payload };
    },
    setArchives(state, action) {
      return { ...state, archives: action.payload };
    },
  },
});

export const { setBlogs, setCategories, setArchives } = blogSlice.actions;
export default blogSlice.reducer;
