import { Col, Progress, Row } from "antd";
import constants from "../../../constants";
import { useSelector } from "react-redux";

const ProgressBar = () => {
  const page = useSelector((state) => state.newForm.page);
  return (
    <>
      <Row justify="center" style={{ marginBottom: "3%" }}>
        <Col xs={20} sm={20} md={20} lg={20}>
          <Progress
            percent={(page / constants.MAX_PAGES) * 100}
            showInfo={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProgressBar;
