import { Col, Collapse, Row } from "antd";
import { useState } from "react";

const FAQCollapse = ({ label, children }) => {
  const [clicked, setClicked] = useState(false);

  const labelStyle = clicked
    ? { fontWeight: "bold", opacity: "0.8", color: "#9830ff" }
    : { fontWeight: "bold", opacity: "0.8" };

  return (
    <>
      <Row justify="center">
        <Col xs={24} sm={22} md={20} lg={18} xl={16} xxl={16}>
          <Collapse
            size="large"
            onChange={() => setClicked(!clicked)}
            items={[
              {
                key: "1",
                label: <div style={labelStyle}>{label}</div>,
                children: <div style={{ opacity: "0.75" }}>{children}</div>,
                style: {
                  background: "white",
                  border: "1px solid #cccccc",
                  borderRadius: "5px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                },
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default FAQCollapse;
