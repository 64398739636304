import { Collapse } from "antd";
import formOptions from "../../newForm/formFields/formOptions";

const SettingsCollapseable = ({ settings }) => {
  const Panel = Collapse.Panel;
  const interests = settings.interests.map((interest) => {
    return formOptions.interestOptions.find(
      (option) => option.value === interest
    ).label;
  });
  const revenueModels = settings.revenueModels.map((model) => {
    return formOptions.revenueModels.find((option) => option.value === model)
      .label;
  });
  const technologies = settings.technology.map((technology) => {
    return formOptions.technologyOptions.find(
      (option) => option.value === technology
    ).label;
  });
  const industries = settings.industry.map((industry) => {
    return formOptions.industryOptions.find(
      (option) => option.value === industry
    ).label;
  });
  const investmentModels = settings.investments.map((investment) => {
    return formOptions.investmentOptions.find(
      (option) => option.value === investment
    ).label;
  });
  const competition = settings.competition.map((competition) => {
    return formOptions.competitionOptions.find(
      (option) => option.value === competition
    ).label;
  });
  const businessModels = settings.businessModel.map((model) => {
    return formOptions.businessModelOptions.find(
      (option) => option.value === model
    ).label;
  });
  const productTypes = settings.productType.map((type) => {
    return formOptions.productTypeOptions.find(
      (option) => option.value === type
    ).label;
  });
  const additionalFilters = settings.additionalFilters.map((filter) => {
    return formOptions.additionalFilters.find(
      (option) => option.value === filter
    ).label;
  });

  return (
    <>
      <Collapse>
        <Panel header="🌟 Interests">
          {interests.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </Panel>
        <Panel header="💹 Revenue Models">
          {revenueModels.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </Panel>
        <Panel header="🛠️ Technologies">
          {technologies.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </Panel>
        <Panel header="🏭 Industries">
          {industries.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </Panel>
        <Panel header="💼 Investment Models">
          {investmentModels.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </Panel>
        <Panel header="🦁 Competition Levels">
          {competition.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </Panel>
        <Panel header="🏢 Business Models">
          {businessModels.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </Panel>
        <Panel header="📦 Product Types">
          {productTypes.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </Panel>
        <Panel header="🔍 Additional Filters">
          {additionalFilters.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </Panel>
      </Collapse>
    </>
  );
};

export default SettingsCollapseable;
