import axios from "axios";
import routes from "../routes";

const postBusinessIdeas = async (form) => {
  try {
    const response = await axios.post(routes.postBusinessIdeasRoute, form);
    return response.data.plan_id;
  } catch (e) {
    if (e.response) {
      if (e.response.status === 429) {
        return { error: 429 };
      } else {
        throw e;
      }
    } else {
      throw e;
    }
  }
};

const pollBusinessIdeasCompletion = async (id) => {
  const response = await axios.get(
    `${routes.pollBusinessIdeasCompletionRoute}/${id}`
  );
  const loading = response.data.status === "completed" ? false : true;
  return {
    loading: loading,
    percentage: response.data.percentage,
  };
};

const getBusinessIdeas = async (id) => {
  const response = await axios.get(`${routes.getBusinessIdeasRoute}/${id}`);
  return response.data;
};

export default {
  postBusinessIdeas,
  getBusinessIdeas,
  pollBusinessIdeasCompletion,
};
