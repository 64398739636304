import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { convertTime, hashImage } from "../../util";

const FeaturedPost = ({ post }) => {
  const date = convertTime(post.random_date);

  return (
    <>
      <div className="featuredPost">
        <Row justify="space-between" gutter={10}>
          <Col xs={{ order: 2, span: 24 }} md={{ order: 1, span: 12 }}>
            <div style={{ color: "green" }}>{post.category}</div>
            <h1>{post.title}</h1>
            <p>
              Posted: {date.day}/{date.month}/{date.year}
            </p>
            <p>{post.meta_description}...</p>
            <Link to={`/blog/${post.slug}`}>Continue reading...</Link>
          </Col>
          <Col xs={{ order: 1, span: 24 }} md={{ order: 2, span: 12 }}>
            <img
              src={`/blogImages/${hashImage(post.title)}.jpg`}
              className="responsiveImage blogImage"
              alt={post.title}
              loading="lazy"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FeaturedPost;
