export const namecheapDomains = {
  ".com": 10.28,
  ".net": 11.98,
  ".org": 8.98,
  ".io": 39.98,
  ".co": 9.48,
  ".ai": 79.98,
  ".co.uk": 6.98,
  ".ca": 11.98,
  ".dev": 12.98,
  ".me": 8.98,
  ".de": 6.98,
  ".app": 14.98,
  ".in": 7.98,
  ".is": 45.98,
  ".eu": 4.98,
  ".gg": 68.98,
  ".to": 29.98,
  ".ph": 52.98,
  ".nl": 5.98,
  ".id": 22.98,
  ".inc": 998.98,
  ".website": 1.88,
  ".xyz": 2.0,
  ".club": 4.98,
  ".online": 1.88,
  ".info": 3.98,
  ".store": 1.88,
  ".best": 3.98,
  ".live": 3.48,
  ".us": 4.48,
  ".tech": 9.97,
  ".pw": 6.98,
  ".pro": 2.98,
  ".uk": 6.98,
  ".tv": 29.98,
  ".cx": 19.98,
  ".mx": 39.98,
  ".fm": 78.98,
  ".cc": 5.98,
  ".world": 2.48,
  ".space": 1.48,
  ".vip": 4.98,
  ".life": 2.48,
  ".shop": 0.98,
  ".host": 6.98,
  ".fun": 1.48,
  ".biz": 5.48,
  ".icu": 1.69,
  ".design": 9.98,
  ".art": 2.88,
};
