import { Col, Divider, Row } from "antd";
import FeaturedPost from "./FeaturedPost";

const FeaturedPosts = ({ blogPostOne, blogPostTwo }) => {
  return (
    <>
      <div className="featuredPosts">
        <h1>Featured Posts</h1>
        <Divider />
        <Row gutter={25}>
          <Col xs={24} xxl={12} style={{ marginBottom: "2%" }}>
            <FeaturedPost post={blogPostOne} />
          </Col>
          <Col xs={24} xxl={12}>
            <FeaturedPost post={blogPostTwo} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FeaturedPosts;
