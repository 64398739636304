import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Categories = () => {
  const categories = useSelector((state) => state.blog.categories);

  return (
    <>
      <div className="blogPostBody blogPostBodySide">
        <h1>Categories</h1>
        <ul>
          {categories.map((category) => (
            <li key={category}>
              <Link to={`/blog/category/${category}`}>{category}</Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Categories;
