import HowDoesItWork from "./components/HowDoesItWork";
import HomepageHeader from "./components/HomepageHeader";
import { Col, Row, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Pricing from "./components/Pricing";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import TitleMeta from "../../components/global/TitleMeta";
import FAQ from "./components/FAQ";

const Homepage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  return (
    <>
      <TitleMeta
        title={
          "Transform Your Curiosity into Customized Business Ideas. Effortlessly."
        }
        meta={
          "Explore our Business Idea Generator for innovative startup concepts! Whether you're an aspiring entrepreneur or seasoned business owner, our tool offers personalized, creative ideas to ignite your entrepreneurial journey. Get inspired with easy-to-use, tailored suggestions that fit your interests and market trends. Start building your dream business today!"
        }
      />
      <div style={{ marginTop: "5%", marginBottom: "5%" }}>
        <Space direction="vertical" size={100} style={{ width: "100%" }}>
          <HomepageHeader />
          <Row justify="center">
            <Col span={24} style={{ textAlign: "center" }}>
              <DownOutlined
                className="homepageDownIcon"
                onClick={() => {
                  navigate("/#howItWorks");
                }}
              />
            </Col>
          </Row>
          <HowDoesItWork />
          <Pricing />
          <FAQ />
        </Space>
      </div>
    </>
  );
};

export default Homepage;
