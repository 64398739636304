import { Route, Routes } from "react-router";
import Homepage from "../pages/homepage/Homepage";
import NewForm from "../pages/newForm/NewForm";
import PrivacyPolicy from "../pages/misc/PrivacyPol";
import ToS from "../pages/misc/ToS";
import LearnMore from "../pages/misc/LearnMore";
import BlogRouter from "./BlogRouter";
import IdeasView from "../pages/ideas/IdeasView";
import { Col, Row } from "antd";
import { useLocation } from "react-router-dom";

const AppRouter = () => {
  const location = useLocation();
  const homePageActive = location.pathname === "/";

  return (
    <>
      {homePageActive ? (
        <Row className="site-content xBackground defaultPadding">
          <Col xs={24} sm={22} md={20} lg={20} xl={18} xxl={16}>
            <Routes>
              <Route index element={<Homepage />} />
            </Routes>
          </Col>
        </Row>
      ) : (
        <Row className="site-content defaultPadding">
          <Col xs={24} sm={22} md={20} lg={20} xl={18} xxl={16}>
            <Routes>
              <Route path="new" element={<NewForm />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="tos" element={<ToS />} />
              <Route path="learn-more" element={<LearnMore />} />
              <Route path="blog/*" element={<BlogRouter />} />
              <Route path="ideas/:id" element={<IdeasView />} />
            </Routes>
          </Col>
        </Row>
      )}
    </>
  );
};

export default AppRouter;
