import { Route, Routes } from "react-router";
import Blog from "../pages/blog/Blog";
import CategoryView from "../pages/blog/CategoryView";
import ArchiveView from "../pages/blog/ArchiveView";
import BlogPostView from "../pages/blog/BlogPostView";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setArchives, setCategories } from "../reducers/blogReducer";
import blogServices from "../services/blogServices";

const BlogRouter = () => {
  const dispatch = useDispatch();

  const fetchSetCategoriesAndArchives = async () => {
    const categories = await blogServices.getBlogCategories();
    const archives = await blogServices.getBlogArchives();
    dispatch(setCategories(categories));
    dispatch(setArchives(archives));
  };

  useEffect(() => {
    fetchSetCategoriesAndArchives();
  }, []);

  return (
    <>
      <div style={{ marginTop: "2%", marginBottom: "2%" }}>
        <Routes>
          <Route index element={<Blog />} />
          <Route path=":slug" element={<BlogPostView />} />
          <Route path="category/:category" element={<CategoryView />} />
          <Route path="archive/:year/:month" element={<ArchiveView />} />
        </Routes>
      </div>
    </>
  );
};

export default BlogRouter;
