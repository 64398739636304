import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  message: "",
  description: "",
  type: "info",
  duration: 0,
};

const NotificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    setNotification(state, action) {
      return action.payload;
    },
    clearNotification(state, action) {
      return initialState;
    },
  },
});

export const { setNotification, clearNotification } = NotificationSlice.actions;
export default NotificationSlice.reducer;
