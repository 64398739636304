import { createSlice } from "@reduxjs/toolkit";

const hCaptchaSlice = createSlice({
  name: "hCaptchaData",
  initialState: { token: null, ekey: null },
  reducers: {
    setHCaptcha(state, action) {
      return action.payload;
    },
  },
});

export const { setHCaptcha } = hCaptchaSlice.actions;
export default hCaptchaSlice.reducer;
