const interestOptions = [
  { value: "Art & Music", label: "🎨 Art & Music" },
  { value: "Building & Repairs", label: "🛠️ Building & Repairs" },
  { value: "Business", label: "💼 Business" },
  { value: "Cars & Transport", label: "🚗 Cars & Transport" },
  { value: "Children & Family", label: "👨‍👩‍👧 Children & Family" },
  { value: "Clothing & Fashion", label: "👗 Clothing & Fashion" },
  { value: "Craft & Handmade", label: "✂️ Craft & Handmade" },
  { value: "Design & Creative", label: "🎨 Design & Creative" },
  { value: "Events & Activities", label: "🎫 Events & Activities" },
  { value: "Finance & Real Estate", label: "💰 Finance & Real Estate" },
  { value: "Food & Beverage", label: "🍔 Food & Beverage" },
  { value: "Health & Beauty", label: "💄 Health & Beauty" },
  { value: "Home & Garden", label: "🏡 Home & Garden" },
  { value: "Internet & Technology", label: "💻 Internet & Technology" },
  { value: "Pets & Animals", label: "🐕 Pets & Animals" },
  { value: "Sports & Outdoors", label: "⚽ Sports & Outdoors" },
  { value: "Teaching & Coaching", label: "📚 Teaching & Coaching" },
  { value: "Travel & Hospitality", label: "✈️ Travel & Hospitality" },
  { value: "Writing & Publishing", label: "📖 Writing & Publishing" },
  { value: "Gaming & Entertainment", label: "🎮 Gaming & Entertainment" },
  { value: "Science & Research", label: "🔬 Science & Research" },
  { value: "Wellness & Meditation", label: "🧘‍♀️ Wellness & Meditation" },
  { value: "Farming & Agriculture", label: "🌽 Farming & Agriculture" },
  {
    value: "Environment & Sustainability",
    label: "🌍 Environment & Sustainability",
  },
  { value: "Law & Governance", label: "⚖️ Law & Governance" },
  { value: "Investments & Trading", label: "💹 Investments & Trading" },
];

const revenueModels = [
  { value: "Subscription", label: "💳 Subscription" },
  { value: "Advertising", label: "📢 Advertising" },
  { value: "Commission", label: "🤝 Commission" },
  { value: "Product or Service Sales", label: "🛍️ Product or Service Sales" },
  { value: "Freemium", label: "🎁 Freemium" },
  { value: "Usage Based", label: "📈 Usage Based" },
];

const technologyOptions = [
  { value: "Artificial Intelligence", label: "🤖 Artificial Intelligence" },
  { value: "Blockchain", label: "⛓️ Blockchain" },
  { value: "Internet of Things", label: "🌐 Internet of Things" },
  { value: "Virtual Reality", label: "🕶️ Virtual Reality" },
  { value: "Geolocation", label: "🌍 Geolocation" },
  { value: "Augmented Reality", label: "📱 Augmented Reality" },
  { value: "Big Data", label: "📊 Big Data" },
  { value: "Digital Products", label: "💾 Digital Products" },
  { value: "Marketplace", label: "🛍️ Marketplace" },
  { value: "Machine Learning", label: "🧠 Machine Learning" },
  { value: "Robotics", label: "🦾 Robotics" },
  { value: "3D Printing", label: "🖨️ 3D Printing" },
  { value: "Social Network", label: "👥 Social Network" },
  { value: "Cybersecurity", label: "🔒 Cybersecurity" },
  { value: "Drones", label: "📡 Drones" },
  { value: "Collaborative Tools", label: "🤝 Collaborative Tools" },
  { value: "Green Technology", label: "🌿 Green Technology" },
  { value: "Biotechnology", label: "🧬 Biotechnology" },
];

const industryOptions = [
  { value: "Technology", label: "🖥️ Technology" },
  { value: "Education", label: "📚 Education" },
  { value: "Finance", label: "💵 Finance" },
  { value: "Healthcare", label: "🏥 Healthcare" },
  { value: "Manufacturing", label: "🏭 Manufacturing" },
  { value: "Real Estate", label: "🏢 Real Estate" },
  { value: "Transportation", label: "🚗 Transportation" },
  { value: "Retail", label: "🛒 Retail" },
  { value: "Food", label: "🍽️ Food" },
  { value: "Entertainment", label: "🎨 Entertainment" },
  { value: "Home", label: "🏡 Home" },
  { value: "Environment", label: "🌿 Environment" },
  { value: "Consulting", label: "💼 Consulting" },
  { value: "Energy", label: "🔋 Energy" },
  { value: "E-commerce", label: "🎁 E-commerce" },
  { value: "Internet of Things", label: "🌐 Internet of Things" },
  { value: "Space", label: "🌌 Space" },
  { value: "Biotechnology", label: "👩‍🔬 Biotechnology" },
  { value: "Pharmaceuticals", label: "🧪 Pharmaceuticals" },
  { value: "Water & Sanitation", label: "🚰 Water & Sanitation" },
  { value: "Security", label: "👮 Security" },
  { value: "Social", label: "👨‍👩‍👧‍👦 Social" },
  { value: "Mobile", label: "📱 Mobile" },
  { value: "Media", label: "📺 Media" },
  { value: "Marketing", label: "📈 Marketing" },
  { value: "Analytics", label: "📊 Analytics" },
  { value: "E-Learning", label: "📚 E-Learning" },
  { value: "Software", label: "☁️ Software" },
  { value: "Hardware", label: "🖥️ Hardware" },
  { value: "Video Games", label: "👾 Video Games" },
  { value: "Streaming", label: "💻 Streaming" },
];

const investmentOptions = [
  { value: "Low", label: "💰 Low Initial Investment" },
  { value: "Medium", label: "💼 Medium Initial Investment" },
  { value: "High", label: "💸 High Initial Investment" },
];

const competitionOptions = [
  { value: "Low", label: "🐢 Low Competition" },
  { value: "Medium", label: "🦊 Medium Competition" },
  { value: "High", label: "🦁 High Competition" },
];

const businessModelOptions = [
  { value: "b2c", label: "🏠 Business to Customer" },
  { value: "b2b", label: "🏢 Business to Business" },
];

const productTypeOptions = [
  { value: "Physical", label: "📦 Physical" },
  { value: "Digital", label: "💿 Digital" },
  { value: "Service", label: "🛎️ Service" },
];

const additionalFilters = [
  { label: "🧠 No Training Needed", value: "No Training Needed" },
  { label: "💸 Low Start Up Costs", value: "Low Start Up Costs" },
  { label: "📈 Highly Scalable", value: "Highly Scalable" },
  { label: "💰 Quick Profit", value: "Quick Profit" },
  { label: "🤫 Introvert Friendly", value: "Introvert Friendly" },
  { label: "🏠 Working From Home", value: "Working From Home" },
  { label: "🛌 Passive Income", value: "Passive Income" },
  { label: "🌿 Environmentally Friendly", value: "Environmentally Friendly" },
  {
    label: "⚙️ Requires Little Maintenance",
    value: "Requires Little Maintenance",
  },
  { label: "🏪 Franchisable", value: "Franchisable" },
];

export default {
  interestOptions,
  revenueModels,
  technologyOptions,
  industryOptions,
  investmentOptions,
  competitionOptions,
  businessModelOptions,
  productTypeOptions,
  additionalFilters,
};
