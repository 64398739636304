import { StarOutlined } from "@ant-design/icons";

const IdeasGenerated = () => {
  return (
    <>
      <div style={{ marginTop: "2.5%", marginBottom: "2.5%" }}>
        <StarOutlined /> Over 3000+ unique business ideas generated
      </div>
    </>
  );
};

export default IdeasGenerated;
