import { Button, Modal, Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import { clearForm } from "../../../reducers/newFormReducer";

const NewFormModal = ({ showResetModal, setShowResetModal }) => {
  const dispatch = useDispatch();

  const handleFormReset = () => {
    dispatch(clearForm());
    setShowResetModal(false);
  };

  return (
    <>
      <Modal
        title="Welcome Back!"
        open={showResetModal}
        onCancel={() => setShowResetModal(false)}
        footer={[
          <Popconfirm
            key="reset"
            title="Reset Form?"
            description="Are you sure you want to reset your form?"
            onConfirm={handleFormReset}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>No, Start Over</Button>
          </Popconfirm>,
          <Button
            key="keep"
            onClick={() => setShowResetModal(false)}
            type="primary"
          >
            Yes, Continue
          </Button>,
        ]}
      >
        <p>
          It looks like you started filling out a form during your last visit.
          We've saved your progress for your convenience.
        </p>
        <p>Would you like to continue where you left off?</p>
      </Modal>
    </>
  );
};

export default NewFormModal;
