import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import {Provider} from "react-redux";
import store from "./store.js";
import * as Sentry from "@sentry/react";

const IS_DEV = import.meta.env.VITE_DEV;

if (!IS_DEV) {
    Sentry.init({
        dsn: "https://64b8ef515a26740ee5fa357ddaabfbfe@o4506383329853440.ingest.sentry.io/4506383332016128",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    "localhost",
                    "https://businessideagenerator.io",
                    "https://backend.vps.businessideagenerator.io"
                ],
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.createRoot(document.getElementById("root")).render(
    <Provider store={store}>
        <App/>
    </Provider>
);
