const DEV = import.meta.env.VITE_DEV;

const MAX_PAGES = 10;

const WEBSITE_TITLE = "BusinessIdeaGenerator.io";

export const BASE_URL = DEV
    ? "http://localhost:8000"
    : "https://backend.vps.businessideagenerator.io";

export const HCAPTCHA_SITE_KEY = "3754333c-eb2c-45a4-853f-43e2381c2b72";

export default {MAX_PAGES, WEBSITE_TITLE};
