import { Col, Row } from "antd";
import reportImage from "../../../assets/3-report.png";
import GenerateBusinessIdeasButton from "./GenerateBusinessIdeasButton";
import IdeasGenerated from "./IdeasGenerated";

const HomepageHeader = () => {
  return (
    <>
      <Row
        align="middle"
        justify="center"
        gutter={32}
        style={{ marginTop: "2%" }}
      >
        <Col xs={24} lg={10} id="tryIt">
          <h1 style={{ fontSize: "3em" }}>
            Transform Your Curiosity into Customized Business Ideas.
            <span style={{ color: "#00008B" }}> Effortlessly.</span>
          </h1>
          <h2 className="pinkPurpleGradientText">
            The #1 Business Idea Generator
          </h2>
          <p style={{ fontSize: "1.2em", opacity: "0.7" }}>
            From a quick form to your future business.
          </p>
          <div style={{ marginBottom: "2.5%" }}>
            <GenerateBusinessIdeasButton />
          </div>
          <div>
            <IdeasGenerated />
          </div>
        </Col>
        <Col xs={24} lg={14}>
          <img
            src={reportImage}
            alt="Image showing a completed business ideas report"
            className="responsiveImage"
            style={{
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
              borderRadius: "10px",
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default HomepageHeader;
