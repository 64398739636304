import { Collapse, Divider, List, Table } from "antd";
import { namecheapDomains } from "./domainCosts";

const IdeasCollapseable = ({ ideas, settings }) => {
  const domainColumns = [
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      render: (text) => (
        <a
          href={`https://www.namecheap.com/domains/registration/results/?domain=${text}`}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Availability",
      dataIndex: "availability",
      key: "availability",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Minimum Cost",
      dataIndex: "cost",
      key: "cost",
      render: (text) => <div>{text}</div>,
    },
  ];

  const items = ideas.map((idea, index) => {
    return {
      key: index,
      label: (
        <span>
          {idea.emoji} <strong>{idea.title}</strong>
          <span style={{ opacity: 0.6 }}>
            {" "}
            {idea.summary.slice(0, idea.summary.indexOf(".") + 1)}
          </span>
        </span>
      ),
      children: (
        <>
          <h1>
            {idea.emoji} {idea.title}
          </h1>
          <p>{idea.summary}</p>
          <Divider />

          <h2 style={{ textAlign: "center" }}>💼 Business</h2>
          <h3>⭐ Unique Selling Proposition</h3>
          <p>{idea.unique_selling_proposition}</p>
          <br />
          <h3>🌱 Scalability and Growth Potential</h3>
          <p>{idea.scalability_and_growth_potential}</p>
          <br />
          <h3>📍 Location and Logistics</h3>
          <p>{idea.location_and_logistics}</p>
          <Divider />

          <h2 style={{ textAlign: "center" }}>♞ Strategy</h2>
          <h3>🚀 Quickstart</h3>
          <p>{idea.quickstart}</p>
          <br />
          <h3>💸 Financial Planning</h3>
          <p>{idea.financial_planning}</p>
          <br />
          <h3>👥 Target Audience</h3>
          <p>{idea.target_audience}</p>
          <br />
          <h3>™️ Marketing and Branding Strategy</h3>
          <p>{idea.marketing_and_branding_strategy}</p>
          <br />
          <h3>💰 Exit Strategy</h3>
          <p>{idea.exit_strategy}</p>
          <br />
          <h3>🔍 SEO Search Terms</h3>
          <List
            itemLayout="horizontal"
            dataSource={idea.url_information[0].url.map((url) => {
              return { title: url };
            })}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  description={
                    <>
                      <span style={{ fontWeight: "bold" }}>{index + 1}. </span>
                      <a href={item.title}>{item.title}</a>
                    </>
                  }
                />
              </List.Item>
            )}
          />
          <br />
          <h3>💻 Online Domains</h3>
          <Table
            columns={domainColumns}
            dataSource={idea.domain_names.map((domain, index) => ({
              key: index,
              domain: domain.domain_name,
              availability: domain.is_available ? "✅" : "❌",
              cost: domain.is_available
                ? namecheapDomains[
                    domain.domain_name.slice(domain.domain_name.indexOf("."))
                  ]
                  ? `$${
                      namecheapDomains[
                        domain.domain_name.slice(
                          domain.domain_name.indexOf(".")
                        )
                      ]
                    }`
                  : "Not found"
                : "Unavailable",
            }))}
            scroll={{ x: "max-content" }}
            pagination={false}
          />
          <Divider />

          <h2 style={{ textAlign: "center" }}>💹 Market Information</h2>
          <h3>🤝 Market Demand</h3>
          <p>{idea.market_demand}</p>
          <br />
          <h3>🥊 Market Competition</h3>
          <p>{idea.market_competition}</p>
          <br />
          <h3>📊 Market Size</h3>
          <p>{idea.market_size}</p>
          <br />
          <h3>📈 Market Trends</h3>
          <p>{idea.market_trends}</p>
          <Divider />

          <h2 style={{ textAlign: "center" }}>🧭 Additional Information</h2>
          <h3>⚖️ Regulatory Requirements</h3>
          <p>{idea.regulatory_requirements}</p>
          <br />
          <h3>💻 Technology Needs</h3>
          <p>{idea.technology_needs}</p>
          <br />
          <h3>🌍 Cultural and Ethical Considerations</h3>
          <p>{idea.cultural_and_ethical_considerations}</p>
          <Divider />

          <h2 style={{ textAlign: "center" }}>🤔 Reasoning</h2>
          <h3>🌟 Interest Reason</h3>
          <p>{idea.interest_reason}</p>
          <br />
          <h3>💹 Revenue Model Reason</h3>
          <p>{idea.revenueModel_reason}</p>
          <br />
          <h3>🛠️ Technology Reason</h3>
          <p>{idea.technology_reason}</p>
          <br />
          <h3>🏭 Industry Reason</h3>
          <p>{idea.industry_reason}</p>
          <br />
          <h3>💼 Investment Reason</h3>
          <p>{idea.investment_reason}</p>
          <br />
          <h3>🦁 Competition Reason</h3>
          <p>{idea.competition_reason}</p>
          <br />
          <h3>🏢 Business Model Reason</h3>
          <p>{idea.businessModel_reason}</p>
          <br />
          <h3>📦 Product Type Reason</h3>
          <p>{idea.productType_reason}</p>
          {settings.additionalFilters.length > 0 ? (
            <>
              <br />
              <h3>🔍 Additional Filter Reasons</h3>
              <p>{idea.additionalFilters_reason}</p>
            </>
          ) : null}
        </>
      ),
    };
  });
  return (
    <>
      <Collapse items={items} />
    </>
  );
};

export default IdeasCollapseable;
