import BaseMultiDropdownForm from "./formFields/BaseMultiDropdownForm";
import formOptions from "./formFields/formOptions";
import BaseMultiCheckboxForm from "./formFields/BaseMultiCheckboxForm";
import FinalFormStep from "./formFields/FinalFormStep";
import TitleMeta from "../../components/global/TitleMeta";
import ideasServices from "../../services/ideasServices";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelSubmit,
  clearForm,
  emptyState,
} from "../../reducers/newFormReducer";
import { setNotification } from "../../reducers/NotificationReducer";
import { useEffect, useState } from "react";
import NewFormModal from "./components/NewFormModal";
import { Col, Row } from "antd";

const NewForm = () => {
  const newForm = useSelector((state) => state.newForm);
  const captchaData = useSelector((state) => state.hCaptcha);
  const page = newForm.page;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showResetModal, setShowResetModal] = useState(false);

  useEffect(() => {
    if (newForm !== emptyState) {
      setShowResetModal(true);
    }
  }, []);

  const handleSubmit = async () => {
    const data = {
      formData: newForm,
      captchaData: captchaData,
    };
    try {
      const response = await ideasServices.postBusinessIdeas(data);
      if (response.error === 429) {
        handleRateLimitReached();
      } else {
        handleSuccess(response);
      }
    } catch (e) {
      handleError();
    }
  };

  const handleRateLimitReached = () => {
    dispatch(cancelSubmit());
    dispatch(
      setNotification({
        show: true,
        message: "Limit reached",
        description:
          "You have requested your maximum number of business ideas for the day, please wait 24 hours.",
        type: "warning",
        duration: 5000,
      })
    );
  };

  const handleSuccess = (response) => {
    dispatch(clearForm());
    navigate(`/ideas/${response}`);
    dispatch(
      setNotification({
        show: true,
        message: "Your business ideas are being generated!",
        description:
          "We have received your request. Please be patient, as this will take a while.",
        type: "success",
        duration: 5000,
      })
    );
  };

  const handleError = () => {
    dispatch(cancelSubmit());
    dispatch(
      setNotification({
        show: true,
        message: "Something went wrong",
        description:
          "Please try again in a few minutes, your form has been saved.",
        type: "error",
        duration: 5000,
      })
    );
  };

  const returnsFormField = () => {
    switch (page) {
      case 0:
        return (
          <BaseMultiDropdownForm
            formKey="interests"
            placeholder={"Select your interests (multiple allowed)..."}
            name={"Interests"}
            messageOnFail={"Please select at least one interest"}
            options={formOptions.interestOptions}
            required={true}
          >
            <h1>What are your interests? 🌟</h1>
            <div>
              Identify areas of passion or expertise that resonate with you,
              guiding your entrepreneurial journey.
            </div>
          </BaseMultiDropdownForm>
        );
      case 1:
        return (
          <BaseMultiDropdownForm
            formKey="revenueModels"
            placeholder={"Select your revenue model (multiple allowed)..."}
            name={"Revenue Models"}
            messageOnFail={"Please select at least one revenue model"}
            options={formOptions.revenueModels}
            required={true}
          >
            <h1>What kind of revenue model are you looking for? 💹</h1>
            <div>
              Choose the primary method through which your business will
              generate income, aligning with your goals and market dynamics.
            </div>
          </BaseMultiDropdownForm>
        );
      case 2:
        return (
          <BaseMultiDropdownForm
            formKey="technology"
            placeholder={"Choose a technology (multiple allowed)..."}
            name={"Technologies"}
            messageOnFail={"Please select at least one technology"}
            options={formOptions.technologyOptions}
            required={true}
          >
            <h1>Which technologies will your business utilize? 🛠️</h1>
            <div>
              Pick the foundational technology that will amplify your product or
              service, setting you apart in the digital age.
            </div>
          </BaseMultiDropdownForm>
        );
      case 3:
        return (
          <BaseMultiDropdownForm
            formKey="industry"
            placeholder={"Pick an industry (multiple allowed)..."}
            name={"Industries"}
            messageOnFail={"Please select at least one industry"}
            options={formOptions.industryOptions}
            required={true}
          >
            <h1>What industry are you targeting? 🏭</h1>
            <div>
              Select the sector that aligns with your business vision, shaping
              your market reach and strategic positioning.
            </div>
          </BaseMultiDropdownForm>
        );
      case 4:
        return (
          <BaseMultiCheckboxForm
            formKey="investments"
            name={"Investment"}
            messageOnFail={"Please select at least one initial investment"}
            options={formOptions.investmentOptions}
            required={true}
          >
            <h1>Investment 💼</h1>
            <div>
              Determine the initial investment required to launch or grow your
              business, ranging from low to high.
            </div>
          </BaseMultiCheckboxForm>
        );
      case 5:
        return (
          <BaseMultiCheckboxForm
            formKey="competition"
            name={"Competition"}
            messageOnFail={"Please select at least one level of competition"}
            options={formOptions.competitionOptions}
            required={true}
          >
            <h1>Competition 🦁</h1>
            <div>
              Consider the level of competition in your chosen market or
              industry, influencing pricing strategies and success.
            </div>
          </BaseMultiCheckboxForm>
        );
      case 6:
        return (
          <BaseMultiCheckboxForm
            formKey="businessModel"
            name={"Business Model"}
            messageOnFail={"Please select at least one business model"}
            options={formOptions.businessModelOptions}
            required={true}
          >
            <h1>Business Model 🏢</h1>
            <div>
              Select what type of customers you want to target with your
              business.
            </div>
          </BaseMultiCheckboxForm>
        );
      case 7:
        return (
          <BaseMultiCheckboxForm
            formKey="productType"
            name={"Product Type"}
            messageOnFail={"Please select at least one product type"}
            options={formOptions.productTypeOptions}
            required={true}
          >
            <h1>Product Type 📦</h1>
            <div>Select the type of product you offer.</div>
          </BaseMultiCheckboxForm>
        );
      case 8:
        return (
          <BaseMultiCheckboxForm
            formKey="additionalFilters"
            name={"Additional Filters"}
            messageOnFail={""}
            options={formOptions.additionalFilters}
            required={false}
          >
            <h1>Additional Filters 🔍</h1>
            <div>
              Select any additional filters that apply to your business.
            </div>
          </BaseMultiCheckboxForm>
        );
      case 9:
        return (
          <FinalFormStep>
            <h1>Almost there 🎉</h1>
          </FinalFormStep>
        );
      case 10:
        handleSubmit();
        break;
    }
  };

  return (
    <>
      <TitleMeta
        title={"Generate Your Business Ideas"}
        meta={
          "Shape your next business venture with our tailored idea generation form. Answer simple questions about your interests and industry preferences to receive personalized business ideas. Ideal for aspiring entrepreneurs ready to explore unique opportunities and innovative concepts specific to their goals."
        }
      />
      <Row align="middle" justify="center">
        <Col xs={24} sm={20} md={18} lg={18} xl={16} xxl={14}>
          <div
            style={{
              paddingBottom: "10%",
            }}
            className="newForm"
          >
            {returnsFormField()}
            <NewFormModal
              showResetModal={showResetModal}
              setShowResetModal={setShowResetModal}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewForm;
