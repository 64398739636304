import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <div className="blogPostBody blogPostBodySide">
        <h1>About</h1>
        <p>
          About Unlock your potential with{" "}
          <Link to="/">BusinessIdeaGenerator.io!</Link> Get personalized,
          data-driven business idea recommendations tailored to your
          preferences. Save time, money, and grow a thriving business
          effortlessly.{" "}
          <Link to="/">Try create a business idea plan for free now!</Link>
        </p>
      </div>
    </>
  );
};

export default About;
