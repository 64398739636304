import { Col, Divider, Row, Space } from "antd";
import About from "./blogPostBody/About";
import Categories from "./blogPostBody/Categories";
import Archives from "./blogPostBody/Archives";
import { useLocation } from "react-router-dom";
import { convertRawHTMLToHTML, convertTime, hashImage } from "../../util";
import BlogPostBodyTitleMeta from "../BlogPostBodyTitleMeta";

const BlogPostBody = ({ blogPost }) => {
  const date = convertTime(blogPost.random_date);
  const location = useLocation();
  const customTitleMeta = location.pathname === "/blog" ? false : true;

  return (
    <>
      {customTitleMeta ? (
        <BlogPostBodyTitleMeta
          title={blogPost.title}
          meta={blogPost.meta_description}
        />
      ) : null}
      <Space direction="vertical" size="middle">
        <Row>
          <Col span={24}>
            <div className="headerPost">
              <Row justify="space-between" align="middle" gutter={32}>
                <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                  <h1>{blogPost.title}</h1>
                  <div style={{ color: "green" }}>{blogPost.category}</div>
                  <div>
                    <p style={{ opacity: 0.75 }}>
                      {date.day}/{date.month}/{date.year}
                    </p>
                  </div>
                  <div>{blogPost.meta_description}...</div>
                </Col>
                <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                  <img
                    src={`/blogImages/${hashImage(blogPost.title)}.jpg`}
                    className="responsiveImage blogImage"
                    alt={blogPost.title}
                    loading="lazy"
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row justify="space-between" gutter={25}>
          <Col xs={24} md={16} style={{ marginBottom: "5%" }}>
            <div className="blogPostBody">
              <h1>{blogPost.title}</h1>
              <p>
                Posted: {date.day}/{date.month}/{date.year}
              </p>
              <Divider />
              <div
                dangerouslySetInnerHTML={convertRawHTMLToHTML(blogPost.content)}
              />
            </div>
          </Col>
          <Col xs={24} md={8}>
            <About />
            <Categories />
            <Archives />
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default BlogPostBody;
