import { Helmet } from "react-helmet";
import constants from "../../constants";

const TitleMeta = ({ title, meta }) => {
  return (
    <>
      <Helmet>
        <title>{`${constants.WEBSITE_TITLE} | ${title}`}</title>
        <meta name="description" content={`${meta}`} />
      </Helmet>
    </>
  );
};

export default TitleMeta;
