import { Divider } from "antd";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Divider style={{ margin: "0%", paddingTop: "1%" }} />
      <footer style={{ textAlign: "center", paddingBottom: "2%" }}>
        © 2023 BusinessIdeaGenerator.io ·{" "}
        <Link to="/learn-more">About us</Link> · <Link to="/tos">ToS</Link> ·{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>
      </footer>
    </>
  );
};

export default Footer;
