import { Button, Col, Row, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { previousPage } from "../../../reducers/newFormReducer";
import constants from "../../../constants";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

const BackNextSubmit = () => {
  const size = "large";
  const page = useSelector((state) => state.newForm.page);
  const dispatch = useDispatch();

  return (
    <>
      <Row justify="center" gutter={10}>
        <Col xs={12} lg={12}>
          {page <= 0 ? (
            <Button disabled size={size} block icon={<ArrowLeftOutlined />}>
              Back
            </Button>
          ) : (
            <Button
              onClick={() => dispatch(previousPage())}
              size={size}
              block
              icon={<ArrowLeftOutlined />}
            >
              Back
            </Button>
          )}
        </Col>
        <Col xs={12} lg={12}>
          {page + 1 === constants.MAX_PAGES ? (
            <Button
              htmlType="submit"
              block
              size={size}
              icon={<CheckCircleOutlined />}
              className="pinkOrangeGradientButton adjust_button_hover_drawer"
            >
              Submit
            </Button>
          ) : (
            <Button
              htmlType="submit"
              size={size}
              block
              icon={<ArrowRightOutlined />}
              type="primary"
            >
              Next
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default BackNextSubmit;
