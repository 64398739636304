import { Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import BackNextSubmit from "../components/BackNextSubmit";
import ProgressBar from "../components/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { nextPage, updateForm } from "../../../reducers/newFormReducer";
import { useEffect } from "react";

const BaseMultiDropdownForm = ({
  formKey,
  placeholder,
  name,
  messageOnFail,
  options,
  required,
  children,
}) => {
  const [form] = useForm();
  const newForm = useSelector((state) => state.newForm);
  const dispatch = useDispatch();

  useEffect(() => {
    if (newForm[formKey].length === 0) {
      form.resetFields();
    }
  }, [newForm]);

  return (
    <>
      <div className="centerText">
        <div style={{ marginBottom: "5%" }}>{children}</div>
        <Form form={form} onFinish={() => dispatch(nextPage())}>
          <Form.Item
            name={name}
            rules={[{ required: required, message: messageOnFail }]}
            initialValue={newForm[formKey]}
          >
            <Select
              mode="multiple"
              options={options}
              placeholder={placeholder}
              maxTagCount="responsive"
              value={newForm[formKey]}
              onChange={(newValues) => dispatch(updateForm(formKey, newValues))}
            />
          </Form.Item>
          <ProgressBar />
          <BackNextSubmit />
        </Form>
      </div>
    </>
  );
};

export default BaseMultiDropdownForm;
