import { Col, Row, Space } from "antd";
import imageOne from "../../../assets/1-form.png";
import imageTwo from "../../../assets/2-loading.png";
import imageThree from "../../../assets/3-report.png";
import GenerateBusinessIdeasButton from "./GenerateBusinessIdeasButton";

const HowDoesItWork = () => {
  const imageStyle = {
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
    borderRadius: "10px",
  };

  return (
    <>
      <Space direction="vertical" size={25}>
        <Row justify="center" align="middle" gutter={50}>
          <Col span={24}>
            <h1 style={{ textAlign: "center" }} id="howItWorks">How does it work?</h1>
          </Col>
        </Row>

        <Row justify="center" align="middle" gutter={50}>
          <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 1 }}>
            <img
              src={imageOne}
              alt="Image showing our simple form for our business idea generator"
              className="responsiveImage"
              style={imageStyle}
            />
          </Col>
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 1 }}>
            <h2 style={{ color: "#00008B" }}>1. Fill in a Simple Form</h2>
            <p>
              Our form asks you a few important multiple choice questions so
              that our app knows what kind of business ideas to generate
              specially for you.
            </p>
          </Col>
        </Row>

        <Row justify="center" align="middle" gutter={50}>
          <Col
            xs={{ span: 24, order: 3 }}
            lg={{ span: 12, order: 3 }}
            className="text-right-lg"
          >
            <h2 style={{ color: "#00008B" }}>2. Our App Goes to Work</h2>
            <p>
              The data comes to us and our app starts analyzing your form to
              generate unique ideas for you.
            </p>
          </Col>
          <Col xs={{ span: 24, order: 4 }} lg={{ span: 12, order: 4 }}>
            <img
              src={imageTwo}
              alt="Image showing our app working on generating ideas"
              className="responsiveImage"
              style={imageStyle}
            />
          </Col>
        </Row>

        <Row justify="center" align="middle" gutter={50}>
          <Col xs={{ span: 24, order: 6 }} lg={{ span: 12, order: 5 }}>
            <img
              src={imageThree}
              alt="Image showing a complete business ideas report"
              className="responsiveImage"
              style={imageStyle}
            />
          </Col>
          <Col xs={{ span: 24, order: 5 }} lg={{ span: 12, order: 6 }}>
            <h2 style={{ color: "#00008B" }}>
              3. Receive Unique Business Ideas
            </h2>
            <p>
              The ideas are delivered to you in an easily digestible format with
              important business and market information.
            </p>
          </Col>
        </Row>

        <Row justify="center" style={{ marginTop: "2%" }}>
          <Col xs={20} lg={12}>
            <GenerateBusinessIdeasButton />
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default HowDoesItWork;
