import HCaptcha from "@hcaptcha/react-hcaptcha";
import { HCAPTCHA_SITE_KEY } from "../../../constants";

const CaptchaContainer = ({
  handleCaptchaValidation,
  handleCaptchaInvalidation,
}) => {
  return (
    <>
      <div className="centerText">
        <HCaptcha
          sitekey={HCAPTCHA_SITE_KEY}
          onVerify={(token, ekey) => {
            handleCaptchaValidation(token, ekey);
          }}
          onExpire={() => handleCaptchaInvalidation()}
          onClose={() => handleCaptchaInvalidation()}
          onChalExpired={() => handleCaptchaInvalidation()}
        />
      </div>
    </>
  );
};

export default CaptchaContainer;
