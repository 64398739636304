import { Alert, Col, Row } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearNotification } from "../../reducers/NotificationReducer";

const Notification = () => {
  const notification = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  useEffect(() => {
    if (notification.show) {
      setTimeout(() => {
        dispatch(clearNotification());
      }, notification.duration);
    }
  }, [notification.show]);

  return (
    <>
      {notification.show ? (
        <>
          <Row justify="center" style={{ marginTop: "1%", marginBottom: "1%" }}>
            <Col xs={24} sm={22} md={18} lg={16}>
              <Alert
                message={notification.message}
                description={notification.description}
                type={notification.type}
                showIcon
              />
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

export default Notification;
