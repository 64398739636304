import { Col, Row } from "antd";
import About from "../blogPost/blogPostBody/About";
import Categories from "../blogPost/blogPostBody/Categories";
import Archives from "../blogPost/blogPostBody/Archives";
import TitleMeta from "../../../../components/global/TitleMeta";
import ArchiveCategoryCard from "./ArchiveCategoryCard";

const ArchiveCategoryView = ({ data, type, extra }) => {
  return (
    <>
      <TitleMeta
        title={`Blog ${extra} ${type} view`}
        meta={`Explore our ${type} of articles ${
          type === "category"
            ? "in the " + extra + " category"
            : "from " + extra
        }. Dive into a wealth of information and insights tailored for you.`}
      />
      <Row justify="space-between" gutter={25}>
        <Col xs={24} md={16} style={{ marginBottom: "5%" }}>
          {data.map((article) => (
            <ArchiveCategoryCard key={article.title} data={article} />
          ))}
        </Col>
        <Col xs={24} md={8}>
          <About />
          <Categories />
          <Archives />
        </Col>
      </Row>
    </>
  );
};

export default ArchiveCategoryView;
