import { createSlice } from "@reduxjs/toolkit";

const localStorageNewForm = localStorage.getItem("newForm");
export const emptyState = {
  page: 0,
  interests: [],
  revenueModels: [],
  technology: [],
  industry: [],
  investments: [],
  competition: [],
  businessModel: [],
  productType: [],
  additionalFilters: [],
  feedback: "",
  email: "",
  receiveUpdates: true,
  tosAccept: false,
};

const initialState =
  localStorageNewForm === null || localStorageNewForm === "null"
    ? emptyState
    : { ...JSON.parse(localStorageNewForm), tosAccept: false };

const newFormSlice = createSlice({
  name: "newForm",
  initialState: initialState,
  reducers: {
    setForm(state, action) {
      return action.payload;
    },
    updateKey(state, action) {
      const tmp = { ...state };
      tmp[action.payload.key] = action.payload.value;
      localStorage.setItem("newForm", JSON.stringify(tmp));
      return tmp;
    },
    clearForm(state, action) {
      localStorage.setItem("newForm", null);
      return emptyState;
    },
    nextPage(state, action) {
      const nextPage = state.page + 1;
      const tmp = { ...state, page: nextPage };
      localStorage.setItem("newForm", JSON.stringify(tmp));
      return tmp;
    },
    previousPage(state, action) {
      const previousPage = state.page - 1;
      return { ...state, page: previousPage };
    },
    cancelSubmit(state, action) {
      const previousPage = state.page - 1;
      const tmp = { ...state, page: previousPage };
      localStorage.setItem("newForm", JSON.stringify(tmp));
      return tmp;
    },
  },
});

export const {
  updateKey,
  setForm,
  clearForm,
  nextPage,
  previousPage,
  cancelSubmit,
} = newFormSlice.actions;

export const updateForm = (key, value) => {
  return (dispatch) => {
    dispatch(updateKey({ key, value }));
  };
};

export default newFormSlice.reducer;
