import { useEffect, useState } from "react";
import { useParams } from "react-router";
import blogServices from "../../services/blogServices";
import Loading from "../../components/global/Loading";
import ArchiveCategoryView from "./components/archiveCategory/ArchiveCategoryView";

const ArchiveView = () => {
  const month = useParams().month;
  const year = useParams().year;
  const [blogPosts, setBlogPosts] = useState(null);

  const getSetBlogPosts = async () => {
    const data = await blogServices.getBlogByArchive(`${year}/${month}`);
    setBlogPosts(data);
  };

  useEffect(() => {
    getSetBlogPosts();
  }, [month, year]);

  if (blogPosts !== null) {
    return (
      <>
        <ArchiveCategoryView
          data={blogPosts}
          type={"Archive"}
          extra={`${year}/${month}`}
        />
      </>
    );
  } else {
    return <Loading />;
  }
};

export default ArchiveView;
