export const loadingMessages = [
  "Cooking up fresh business ideas for you...",
  "Brewing innovative concepts...",
  "Gathering inspiration from the market's latest trends...",
  "Tailoring unique business strategies just for you...",
  "Harvesting the best ideas from the entrepreneurial field...",
  "Composing a blueprint for your business success...",
  "Crafting groundbreaking ideas to propel your venture...",
  "Assembling insights for your next big breakthrough...",
  "Cultivating creative and profitable solutions...",
  "Designing custom business ideas tailored to your vision...",
  "Polishing innovative business concepts for you...",
  "Simmering a mix of creativity and market analysis...",
  "Aligning the stars of opportunity and innovation...",
  "Mapping the terrain of your industry's future...",
  "Weaving together threads of creativity and practicality...",
  "Scouting the horizons for groundbreaking ideas...",
  "Channeling entrepreneurial spirit into actionable plans...",
  "Constructing a foundation for your business journey...",
  "Orchestrating the symphony of your business success...",
  "Navigating through the ocean of market possibilities...",
];
