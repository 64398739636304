import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ideasServices from "../../services/ideasServices";
import Loading from "../../components/global/Loading";
import { Col, Divider, Row } from "antd";
import IdeasCollapseable from "./components/IdeasCollapseable";
import SettingsCollapseable from "./components/SettingsCollapseable";
import TitleMeta from "../../components/global/TitleMeta";

const IdeasView = () => {
  const id = useParams().id;
  const [loading, setLoading] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [count, setCount] = useState(0);
  const [ideas, setIdeas] = useState(null);

  const getIdeas = async () => {
    const data = await ideasServices.getBusinessIdeas(id);
    setIdeas(data);
    setLoading(false);
  };

  const getPollResponse = async () => {
    const response = await ideasServices.pollBusinessIdeasCompletion(id);
    if (!response.loading) {
      getIdeas();
    } else if (response.loading) {
      setLoading(true);
      setPercentage(response.percentage);
      setCount(count + 1);
    }
  };

  useEffect(() => {
    getPollResponse();
  }, []);

  useEffect(() => {
    if (loading === true) {
      setTimeout(() => {
        getPollResponse();
        setCount(count + 1);
      }, 5000);
    }
  }, [count]);

  const metaDescription =
    "Unveil a world of possibilities with our Business Ideas Report Page! Get personalized, innovative, and actionable business ideas tailored to your interests and market trends. Whether you're an aspiring entrepreneur or looking to expand your existing business, our generator offers a diverse range of ideas to ignite your passion and drive your success. Start exploring today and transform your entrepreneurial dreams into reality!";

  if (loading === null || loading) {
    return (
      <>
        <TitleMeta title={"Business Ideas Report"} meta={metaDescription} />
        <Loading percentage={percentage} ideasLoading={true} />
      </>
    );
  } else if (ideas && !loading) {
    return (
      <>
        <TitleMeta
          title={`${ideas.ideas.length} Business Ideas for people that like ${ideas.settings.interests[0]}!`}
          meta={metaDescription}
        />
        <div style={{ marginBottom: "5%" }}>
          <Row justify="center" style={{ textAlign: "center" }}>
            <Col span={24}>
              <h1>🚀 Your Personalized Business Idea Report</h1>
            </Col>
            <Col span={24}>
              <h3>A snapshot of your current business idea preferences.</h3>
            </Col>
            <Col span={24}>
              <i>Your report can be accessed anytime using the URL provided.</i>
            </Col>
          </Row>
          <Divider />
          <h2 style={{ textAlign: "center" }}>💡 Business Ideas</h2>
          <IdeasCollapseable ideas={ideas.ideas} settings={ideas.settings} />
          <Divider />
          <h2 style={{ textAlign: "center" }}>⚙️ Settings Used</h2>
          <SettingsCollapseable settings={ideas.settings} />
        </div>
      </>
    );
  }
};

export default IdeasView;
