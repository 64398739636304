import { useParams } from "react-router";
import { useEffect, useState } from "react";
import blogServices from "../../services/blogServices";
import Loading from "../../components/global/Loading";
import ArchiveCategoryView from "./components/archiveCategory/ArchiveCategoryView";

const CategoryView = () => {
  const category = useParams().category;
  const [blogPosts, setBlogPosts] = useState(null);

  const getSetBlogPosts = async () => {
    const data = await blogServices.getBlogByCategory(category);
    setBlogPosts(data);
  };

  useEffect(() => {
    getSetBlogPosts();
  }, [category]);

  if (blogPosts !== null) {
    return (
      <>
        <ArchiveCategoryView
          data={blogPosts}
          type={"Category"}
          extra={category}
        />
      </>
    );
  } else {
    return <Loading />;
  }
};

export default CategoryView;
