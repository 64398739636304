import { Card, Col, Divider, Row, Space } from "antd";
import GenerateBusinessIdeasButton from "./GenerateBusinessIdeasButton";
import { CheckOutlined } from "@ant-design/icons";
import IdeasGenerated from "./IdeasGenerated";

const Pricing = () => {
  return (
    <>
      <Space direction="vertical" size={25} style={{ width: "100%" }}>
        <Row justify="center">
          <Col span={24} style={{ textAlign: "center" }}>
            <h1 id="pricing">Pricing</h1>
          </Col>
        </Row>

        <Row justify="center">
          <Col span={24} style={{ textAlign: "center" }}>
            <div style={{ fontSize: "1.5em", fontWeight: "bold" }}>
              Turn interests into your future business
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <Col span={20}>
            <Card style={{ paddingBottom: "2.5%" }}>
              <Row justify="center" align="middle">
                <Col xs={24} lg={14} style={{ textAlign: "center" }}>
                  <Space direction="vertical" size={10}>
                    <div style={{ fontSize: "4em", fontWeight: "bold" }}>
                      Free*
                    </div>
                    <div style={{ opacity: "0.75" }}>
                      *For a limited period of time.
                    </div>
                    <GenerateBusinessIdeasButton />
                  </Space>
                </Col>
                <Col xs={24} lg={10}>
                  <h2>Free Business Ideas Report Includes:</h2>
                  <li>
                    <CheckOutlined /> 10 unique business ideas
                  </li>
                  <li>
                    <CheckOutlined /> Market analysis for every idea
                  </li>
                  <li>
                    <CheckOutlined /> Strategy overview per idea
                  </li>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <IdeasGenerated />
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default Pricing;
