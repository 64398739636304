import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { hashImage } from "../util";

const HeaderPost = ({ blogPost }) => {
  return (
    <>
      <div className="headerPost">
        <Row justify="space-between" gutter={32} align="middle">
          <Col xs={{ order: 2, span: 24 }} md={{ order: 1, span: 12 }}>
            <h1>{blogPost.title}</h1>
            <p>{blogPost.meta_description}</p>
            <Link to={`/blog/${blogPost.slug}`}>Continue reading...</Link>
          </Col>
          <Col xs={{ order: 1, span: 24 }} md={{ order: 2, span: 12 }}>
            <img
              src={`/blogImages/${hashImage(blogPost)}.jpg`}
              className="responsiveImage blogImage"
              alt={blogPost.title}
              loading="lazy"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default HeaderPost;
