import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppRouter from "./routes/AppRouter";
import { Layout } from "antd";
import Footer from "./components/global/Footer";
import Notification from "./components/global/Notification";
import "./App.css";
import NewNavbar from "./components/global/NewNavbar";

function App() {
  return (
    <>
      <BrowserRouter>
        <Layout className="site-layout">
          <header>
            <NewNavbar />
          </header>
          <Notification />
          <main>
            <Routes>
              <Route path="/*" element={<AppRouter />} />
            </Routes>
          </main>
          <footer>
            <Footer />
          </footer>
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;
