import { useParams } from "react-router";
import BlogPost from "./components/blogPost/BlogPost";
import Loading from "../../components/global/Loading";
import { useEffect, useState } from "react";
import blogServices from "../../services/blogServices";

const BlogPostView = () => {
  const slug = useParams().slug;
  const [blogPost, setBlogPost] = useState(null);

  const getSetBlogPost = async () => {
    const data = await blogServices.getBlogBySlug(slug);
    setBlogPost(data);
  };

  useEffect(() => {
    getSetBlogPost();
  }, []);

  if (blogPost !== null) {
    return (
      <>
        <BlogPost blogPost={blogPost} />
      </>
    );
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default BlogPostView;
