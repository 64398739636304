import { Col, Row, Space } from "antd";
import FAQCollapse from "./FAQCollapse";

const FAQ = () => {
  return (
    <>
      <Space direction="vertical" size={25} style={{ width: "100%" }}>
        <Row justify="center">
          <Col span={24} style={{ textAlign: "center" }}>
            <h1 id="faq">F.A.Q.</h1>
          </Col>
        </Row>
        <FAQCollapse label={"How does the business idea generator work?"}>
          Our business idea generator uses a simple form that covers key aspects
          like desired market competition, personal interests, technology, and
          more. By analyzing your responses, our sophisticated algorithm
          generates in-depth and personalized business ideas tailored to your
          preferences and market opportunities.
        </FAQCollapse>
        <FAQCollapse
          label={"Who can benefit from using this business idea generator?"}
        >
          Anyone looking to start a business but unsure where to begin can
          benefit from our tool. It's especially useful for aspiring
          entrepreneurs, students, and professionals exploring a career change.
        </FAQCollapse>
        <FAQCollapse label={"Is the idea generator free to use?"}>
          Yes, our idea generator is completely free to use for a limited period
          of time.
        </FAQCollapse>
        <FAQCollapse label={"How long does it take to get a business idea?"}>
          Once you complete our simple form, our generator processes your
          answers immediately. You'll receive a detailed business idea within
          seconds.
        </FAQCollapse>
        <FAQCollapse label={"Can I generate more than one business idea?"}>
          Absolutely! After submitting your form our generator will generate 10
          ideas specially for you!
        </FAQCollapse>
        <FAQCollapse
          label={
            "Do you provide resources or guidance for the generated business ideas?"
          }
        >
          While our primary function is to generate business ideas, we also
          offer links to resources and guides on how to start and grow your
          business. These resources are tailored to each specific business idea
          for maximal relevance.
        </FAQCollapse>
      </Space>
    </>
  );
};

export default FAQ;
