export const convertTime = (dateString) => {
  const date = new Date(dateString);
  return {
    year: date.getFullYear(),
    month: (date.getMonth() + 1).toString(),
    day: date.getDate().toString(),
  };
};

export const convertRawHTMLToHTML = (rawHTML) => {
  return { __html: rawHTML };
};

export const hashImage = (title) => {
  let hash = 0;
  for (let i = 0; i < title.length; i++) {
    hash += title.charCodeAt(i);
  }
  return hash % 10;
};

// export const extractTextFromFirstParagraph = (rawHTML) => {
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(rawHTML, "text/html");
//   const firstParagraph = doc.querySelector("p");
//   return firstParagraph ? firstParagraph.textContent : "";
// };
