import { useDispatch, useSelector } from "react-redux";
import TitleMeta from "../../components/global/TitleMeta";
import HeaderPost from "./components/HeaderPost";
import FeaturedPosts from "./components/featuredPosts/FeaturedPosts";
import LatestPostContainer from "./components/latestPost/LatestPostContainer";
import Loading from "../../components/global/Loading";
import { useEffect } from "react";
import blogServices from "../../services/blogServices";
import { setBlogs } from "../../reducers/blogReducer";

const Blog = () => {
  const dispatch = useDispatch();
  const recentBlogs = useSelector((state) => state.blog.blogs);

  const getRecentBlogsAndSet = async () => {
    const recentBlogs = await blogServices.getRecentBlogs();
    dispatch(setBlogs(recentBlogs));
  };

  useEffect(() => {
    getRecentBlogsAndSet();
  }, []);

  if (recentBlogs.length !== 0) {
    return (
      <>
        <TitleMeta
          title={"Blog"}
          meta={
            "Explore innovative and inspiring business ideas on our blog. Dive into expert insights, success stories, and practical tips for entrepreneurs. Whether you're starting up or scaling up, our blog is your go-to resource for creative and actionable business strategies. Join our community of forward-thinking entrepreneurs today!"
          }
        />
        <HeaderPost blogPost={recentBlogs[4]} />
        <FeaturedPosts
          blogPostOne={recentBlogs[1]}
          blogPostTwo={recentBlogs[2]}
        />
        <LatestPostContainer blogPost={recentBlogs[0]} />
      </>
    );
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default Blog;
