import { Link } from "react-router-dom";
import { convertTime } from "../../util";

const ArchiveCategoryCard = ({ data }) => {
  const date = convertTime(data.random_date);
  const style = {
    border: "1px solid gray",
    borderRadius: "10px",
    padding: "3%",
    marginBottom: "2%",
    boxShadow: "1px 1px 7.5px grey",
  };
  return (
    <>
      <div style={style}>
        <div>
          <h3 style={{ color: "green" }}>{data.category}</h3>
        </div>
        <div>
          <h1>{data.title}</h1>
        </div>
        <div style={{ opacity: "0.75" }}>
          <h3>
            Posted: {date.day}/{date.month}/{date.year}
          </h3>
        </div>
        <div>
          <p>{data.meta_description}...</p>
        </div>
        <div>
          <p>
            <Link to={`/blog/${data.slug}`}>Continue reading...</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default ArchiveCategoryCard;
