import { BASE_URL } from "./constants";

const postBusinessIdeasRoute = `${BASE_URL}/business-plan`;
const pollBusinessIdeasCompletionRoute = `${BASE_URL}/business-plan/status`;
const getBusinessIdeasRoute = `${BASE_URL}/business-plan`;

const getBlogCategories = `${BASE_URL}/blog/categories`;
const getBlogArchives = `${BASE_URL}/blog/archives`;
const getRecentBlogs = `${BASE_URL}/blog/recent`;
const getBlogBySlug = `${BASE_URL}/blog`;
const getBlogsByCategory = `${BASE_URL}/blog/category`;
const getBlogsByArchive = `${BASE_URL}/blog/archives`;

export default {
  postBusinessIdeasRoute,
  pollBusinessIdeasCompletionRoute,
  getBusinessIdeasRoute,
  getBlogCategories,
  getBlogArchives,
  getRecentBlogs,
  getBlogBySlug,
  getBlogsByCategory,
  getBlogsByArchive,
};
