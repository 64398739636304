import { Divider } from "antd";
import LatestPost from "./LatestPost";

const LatestPostContainer = ({ blogPost }) => {
  return (
    <>
      <h1>Latest Post</h1>
      <Divider />
      <LatestPost blogPost={blogPost} />
    </>
  );
};

export default LatestPostContainer;
