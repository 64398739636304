import { Col, Progress, Row, Spin } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { loadingMessages } from "../../pages/newForm/loadingMessages";

const Loading = ({ percentage, ideasLoading }) => {
  const progressBar = <Progress percent={percentage} showInfo={false} />;
  const [messageIndex, setMessageIndex] = useState(0);
  const [currentMessage, setCurrentMessage] = useState(loadingMessages[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % loadingMessages.length;
        setCurrentMessage(loadingMessages[nextIndex]);
        return nextIndex;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Row justify="center" style={{ marginTop: "4%" }}>
        <Col style={{ textAlign: "center" }} span={24}>
          <Spin size="large" />
          <Title level={4}>Loading...</Title>
        </Col>
        <Col xs={14} lg={10}>
          {percentage !== null && percentage !== undefined ? progressBar : null}
        </Col>
      </Row>
      {ideasLoading ? (
        <div style={{ textAlign: "center" }} className="loadingMessages">
          {currentMessage}
        </div>
      ) : null}
    </>
  );
};

export default Loading;
