import { configureStore } from "@reduxjs/toolkit";
import blogReducer from "./reducers/blogReducer";
import newFormReducer from "./reducers/newFormReducer";
import hCaptchaReducer from "./reducers/hCaptchaReducer";
import NotificationReducer from "./reducers/NotificationReducer";

const store = configureStore({
  reducer: {
    blog: blogReducer,
    newForm: newFormReducer,
    hCaptcha: hCaptchaReducer,
    notification: NotificationReducer,
  },
});

export default store;
