import BlogPostBody from "./BlogPostBody";

// probably have to parse the incoming html somehow here

const BlogPost = ({ blogPost }) => {
  return (
    <>
      <BlogPostBody blogPost={blogPost} />
    </>
  );
};

export default BlogPost;
