import BlogPost from "../blogPost/BlogPost";

const LatestPost = ({ blogPost }) => {
  return (
    <>
      <BlogPost blogPost={blogPost} />
    </>
  );
};

export default LatestPost;
