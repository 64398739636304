import { Button, Col, Divider, Dropdown, Menu, Row } from "antd";
import logo from "../../assets/logo.webp";
import { Link, useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import GenerateBusinessIdeasButton from "../../pages/homepage/components/GenerateBusinessIdeasButton";

const NewNavbar = () => {
  const navigate = useNavigate();

  const handleClick = (e, hash) => {
    e.preventDefault();
    navigate(`/${hash}`);
  };

  const items = [
    {
      label: (
        <a
          href="/#tryIt"
          onClick={(e) => handleClick(e, "#tryIt")}
          className="navOptions"
        >
          Try it
        </a>
      ),
      key: "tryIt",
    },
    {
      label: (
        <a
          href="/#howItWorks"
          onClick={(e) => handleClick(e, "#howItWorks")}
          className="navOptions"
        >
          How it works
        </a>
      ),
      key: "howItWorks",
    },
    {
      label: (
        <a
          href="/#pricing"
          onClick={(e) => handleClick(e, "#pricing")}
          className="navOptions"
        >
          Pricing
        </a>
      ),
      key: "pricing",
    },
    {
      label: (
        <a
          href="/#faq"
          onClick={(e) => handleClick(e, "#faq")}
          className="navOptions"
        >
          FAQ
        </a>
      ),
      key: "faq",
    },
    {
      label: (
        <a
          href="/blog"
          onClick={(e) => {
            e.preventDefault();
            navigate("/blog");
          }}
          className="navOptions"
        >
          Blog
        </a>
      ),
      key: "blog",
    },
    {
      label: <GenerateBusinessIdeasButton />,
      key: "generateBusinessIdeas",
    },
  ];

  return (
    <>
      <Row
        justify="center"
        align="middle"
        style={{ paddingTop: "0.5%", paddingBottom: "0.5%" }}
      >
        <Col xxl={16} xl={20} lg={22} md={22} sm={22} xs={22}>
          <Row justify="space-between" align="middle">
            <Col lg={8} xs={0}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{
                    width: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/")}
                />
                <Link
                  to="/"
                  style={{
                    fontSize: "1.5em",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  BusinessIdeaGenerator.io
                </Link>
              </div>
            </Col>
            <Col lg={0} xs={12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{
                    width: "40px",
                  }}
                  onClick={() => navigate("/")}
                />
                <Link
                  to="/"
                  style={{
                    fontSize: "1.25em",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  BusinessIdeaGenerator.io
                </Link>
              </div>
            </Col>
            <Col xxl={16} xl={16} lg={16} md={0} sm={0} xs={0}>
              <Row justify="space-between" align="middle">
                {items.map((item) => (
                  <Col key={item.key}>{item.label}</Col>
                ))}
              </Row>
            </Col>
            <Col xxl={0} xl={0} lg={0} md={12} xs={12}>
              <Row justify="end" align="middle">
                <Dropdown menu={{ items }}>
                  <Button icon={<MenuOutlined />} size="large">
                    Menu
                  </Button>
                </Dropdown>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider style={{ margin: "0" }} />
    </>
  );
};

export default NewNavbar;
