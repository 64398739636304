import { Helmet } from "react-helmet";
import constants from "../../../constants";

const BlogPostBodyTitleMeta = ({ title, meta }) => {
  return (
    <>
      <Helmet>
        <title>{`${title} | ${constants.WEBSITE_TITLE} Blog`}</title>
        <meta name="description" content={`${meta}`} />
      </Helmet>
    </>
  );
};

export default BlogPostBodyTitleMeta;
