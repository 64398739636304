import { Checkbox, Col, Form, Input, Row } from "antd";
import BackNextSubmit from "../components/BackNextSubmit";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import ProgressBar from "../components/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { nextPage, updateForm } from "../../../reducers/newFormReducer";
import { Link } from "react-router-dom";
import { setHCaptcha } from "../../../reducers/hCaptchaReducer";
import CaptchaContainer from "../components/CaptchaContainer";

const FinalFormStep = ({ children }) => {
  const [form] = useForm();
  const newForm = useSelector((state) => state.newForm);
  const dispatch = useDispatch();

  const handleCaptchaValidation = (token, ekey) => {
    form.setFieldsValue({ hcaptcha: "complete" });
    dispatch(setHCaptcha({ token, ekey }));
  };

  const handleCaptchaInvalidation = () => {
    form.setFieldsValue({ hcaptcha: "" });
  };

  return (
    <>
      <div className="centerText">{children}</div>
      <div style={{ marginBottom: "1%" }}>
        Additional feedback (How can we make this tool even better?){" "}
        <span style={{ opacity: "0.5" }}>(Optional)</span>
      </div>
      <Form form={form} onFinish={() => dispatch(nextPage())}>
        <Row justify="center">
          <Col xs={24} lg={24}>
            <Form.Item
              name="Feedback"
              rules={[{ required: false }]}
              initialValue={newForm.feedback}
            >
              <TextArea
                value={newForm.feedback}
                onChange={(e) =>
                  dispatch(updateForm("feedback", e.target.value))
                }
                placeholder="Feedback"
              />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ marginBottom: "1%" }}>Email</div>
        <Row justify="center">
          <Col xs={24} lg={24}>
            <Form.Item
              name="Email"
              rules={[{ required: true }]}
              initialValue={newForm.email}
            >
              <Input
                type="email"
                placeholder="you@example.com"
                value={newForm.email}
                onChange={(e) => dispatch(updateForm("email", e.target.value))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="ReceiveUpdates" rules={[{ required: false }]}>
          <Checkbox
            checked={newForm.receiveUpdates}
            onChange={() =>
              dispatch(updateForm("receiveUpdates", !newForm.receiveUpdates))
            }
          >
            I would like to receive updates of future releases.
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="ToSAccept"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message:
                "You must agree to the terms of service before you may continue.",
            },
          ]}
        >
          <Checkbox
            checked={newForm.tosAccept}
            onChange={() =>
              dispatch(updateForm("tosAccept", !newForm.tosAccept))
            }
          >
            I agree with the <Link to="/tos">Terms of Service</Link>.
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="hcaptcha"
          rules={[
            {
              required: true,
              message: "Please complete the captcha to continue.",
            },
          ]}
        >
          <CaptchaContainer
            handleCaptchaValidation={handleCaptchaValidation}
            handleCaptchaInvalidation={handleCaptchaInvalidation}
          />
        </Form.Item>
        <ProgressBar />
        <BackNextSubmit />
      </Form>
    </>
  );
};

export default FinalFormStep;
